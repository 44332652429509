import React from 'react'
// import { YouVisitIWC } from '@ux_bob/yv-iwc'
import YouVisitIWC from 'components/YouVisitIWC'

function ContentfulComponentVirtualTour({ data }) {
  const { institutionId } = data
  return <YouVisitIWC containerWidth="100%" containerHeight="500px" institution={institutionId} />
}

export default ContentfulComponentVirtualTour
