import React from 'react'
import { ModalWrapper, Reoverlay } from 'reoverlay'
import InnerHTML from 'dangerously-set-html-content'
// import ContactForm from '../Contact/ContactForm'

import 'reoverlay/lib/ModalWrapper.css'

//* ==========
//* Partner Slate Form
const x = `<div id="form_9145365b-e0e2-4f9c-93f9-cda84dc4bf09">Loading...</div><script>/*<![CDATA[*/var script = document.createElement('script'); script.async = 1; script.src = 'https://fireup.cmich.edu/register/?id=9145365b-e0e2-4f9c-93f9-cda84dc4bf09&output=embed&div=form_9145365b-e0e2-4f9c-93f9-cda84dc4bf09' + ((location.search.length > 1) ? '&' + location.search.substring(1) : ''); var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(script, s);/*]]>*/</script>`

//* ==========
//* EAB Slate Form
// const x = `<div id="form_ed7c8a3f-7cfd-4458-b109-3a210dd58df1">Loading...</div><script>/*<![CDATA[*/var script = document.createElement('script'); script.async = 1; script.src = 'https://apply.collegeofeab.org/register/?id=ed7c8a3f-7cfd-4458-b109-3a210dd58df1&output=embed&div=form_ed7c8a3f-7cfd-4458-b109-3a210dd58df1' + ((location.search.length > 1) ? '&' + location.search.substring(1) : ''); var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(script, s);/*]]>*/</script>`

const InquireModal = ({ program }) => {
  const closeModal = () => {
    Reoverlay.hideModal()
  }

  return (
    <ModalWrapper
      contentContainerClassName="modalContent"
      wrapperClassName="modal__wrapper"
      onClose={() => Reoverlay.hideModal()}
      animation="slideUp"
    >
      {/* Program: {program} */}
      <InnerHTML html={x} />
      {/* <ContactForm program={program} /> */}

      <button className="closeModal" type="button" onClick={closeModal}>
        Close modal
      </button>
    </ModalWrapper>
  )
}
export default InquireModal
