import React from 'react'
import Paragraph from './Paragraph'

const Headline = ({ headline }) => {
  if (!headline) return null
  return <h2 className="headline">{headline}</h2>
}

const ParagraphWithHeadline = ({ headline, paragraph, cssClass }) => (
  <div className={`paragraphWithHeadline ${cssClass}`}>
    <Headline headline={headline} />
    <Paragraph paragraphHtml={paragraph} />
  </div>
)

export default ParagraphWithHeadline
