import React from 'react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'

const Testimonial = ({ quote, author, image, cssClass, authorProfile, authorLocation }) => (
  <div className={`testimonial ${cssClass}`}>
    {quote && (
      <>
        {image && <Img image={image.gatsbyImageData} alt={image.title || 'Untitled Image'} />}
        <div className="text-container">
          <div className="quote-content" dangerouslySetInnerHTML={{ __html: quote }} />
          <figcaption className="author">
            <div className="author-name">{author}</div>
            <div className="author-profile">{authorProfile}</div>
            <div className="author-location">{authorLocation}</div>
          </figcaption>
        </div>
      </>
    )}
  </div>
)

export default Testimonial
