import React from 'react'
import ContentfulComponentCallToActionCta from 'utils/content-modules/ContentfulComponentCallToActionCta'
import ContentfulComponentHeadline from 'utils/content-modules/ContentfulComponentHeadline'
import ContentfulComponentParagraph from 'utils/content-modules/ContentfulComponentParagraph'
import ContentfulLayoutSetOfTwo from 'utils/content-modules/ContentfulLayoutSetOfTwo'
import ContentfulLayoutSetOfThree from 'utils/content-modules/ContentfulLayoutSetOfThree'
import ContentfulComponentImage from 'utils/content-modules/ContentfulComponentImage'
import ContentfulHeroImage from 'utils/content-modules/ContentfulHeroImage'
import ContentfulComponentTestimonialQuote from 'utils/content-modules/ContentfulComponentTestimonialQuote'
import ContentfulComponentParagraphWithHeadline from 'utils/content-modules/ContentfulComponentParagraphWithHeadline'
import ContentfulComponentStatBlocks from 'utils/content-modules/ContentfulComponentStatBlocks'
import ContentfulComponentCard from 'utils/content-modules/ContentfulComponentCard'
import ContentfulHeader from 'utils/content-modules/ContentfulHeader'
import ContentfulLayoutSection from 'utils/content-modules/ContentfulLayoutSection'
import ContentfulComponentVirtualTour from './content-modules/ContentfulComponentVirtualTour'
// map over components and return only the ones that are enabled
const componentsMap = {
  ContentfulComponentHeadline,
  ContentfulComponentCallToActionCta,
  ContentfulComponentParagraph,
  ContentfulLayoutSetOfTwo,
  ContentfulLayoutSetOfThree,
  ContentfulComponentImage,
  ContentfulHeroImage,
  ContentfulComponentTestimonialQuote,
  ContentfulComponentParagraphWithHeadline,
  ContentfulComponentStatBlocks,
  ContentfulComponentCard,
  ContentfulHeader,
  ContentfulLayoutSection,
  ContentfulComponentVirtualTour,
}

export const getLandingPageModules = (module, index) => {
  const Component = componentsMap[module.internal.type]
  if (Component) {
    return <Component data={module} key={index} />
  }
  return null
}

export default getLandingPageModules
