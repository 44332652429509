import React from 'react'
import CTA from 'components/CTA'

function ContentfulComponentCallToActionCta({ data }) {
  const { childContentfulComponentCallToActionCtaTextTextNode, buttonText, cssClass } = data
  let textHtml
  if (childContentfulComponentCallToActionCtaTextTextNode) {
    textHtml = childContentfulComponentCallToActionCtaTextTextNode.childMarkdownRemark.html
  }
  return <CTA text={textHtml} cssClass={cssClass} buttonText={buttonText} />
}

export default ContentfulComponentCallToActionCta
