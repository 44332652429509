import React from 'react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { Container } from './common'

const Title = ({ title }) => {
  if (!title) return null
  return <div className="card-title" dangerouslySetInnerHTML={{ __html: title }} />
}
const Description = ({ description }) => {
  if (!description) return null
  return <div className="card-description" dangerouslySetInnerHTML={{ __html: description }} />
}

const Card = ({ title, description, image, cssClass }) => (
  <Container type="wrapper" className={cssClass ? `card-container ${cssClass}` : 'card-container'}>
    <Img image={image.gatsbyImageData} alt={image.title || 'Untitled Image'} />
    <div className="text-container">
      <Title title={title} />
      <Description description={description} />
    </div>
  </Container>
)

export default Card
