import React from 'react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import Container from 'components/common/Container'

function HeroImage({ heroImage }) {
  return (
    <Container type="wrapper" className="hero-image">
      <Img
        loading="eager"
        image={heroImage.gatsbyImageData}
        alt={heroImage.description || heroImage.title || 'Untitled Image'}
      />
    </Container>
  )
}

export default HeroImage
