import React from 'react'
import Card from 'components/Card'

function ContentfulComponentCard({ data }) {
  // console.log('ContentfulComponentCard', data)
  const {
    image,
    childContentfulComponentCardDescriptionTextNode,
    childContentfulComponentCardTitleTextNode,
    cssClass,
  } = data
  const { childMarkdownRemark: description } = childContentfulComponentCardDescriptionTextNode
  const { childMarkdownRemark: title } = childContentfulComponentCardTitleTextNode
  return <Card title={title.html} description={description.html} image={image.image} cssClass={cssClass} />
}

export default ContentfulComponentCard
