import React from 'react'
import Testimonial from 'components/Testimonial'

function ContentfulTestimonialQuote({ data }) {
  const {
    author,
    authorProfile,
    authorLocation,
    childContentfulComponentTestimonialQuoteQuoteBlockTextNode,
    image,
    cssClass = '',
  } = data

  let quoteHtml
  if (childContentfulComponentTestimonialQuoteQuoteBlockTextNode) {
    quoteHtml = childContentfulComponentTestimonialQuoteQuoteBlockTextNode.childMarkdownRemark.html
  }

  return (
    <Testimonial
      quote={quoteHtml}
      cssClass={cssClass}
      author={author}
      authorProfile={authorProfile}
      authorLocation={authorLocation}
      image={image}
    />
  )
}

export default ContentfulTestimonialQuote
