import React from 'react'
import StatBlock from 'components/StatBlock'

function ContentfulStatBlock({ data }) {
  const { source, statisticImage, childContentfulComponentStatBlocksStatisticTextNode = {} } = data
  return (
    <StatBlock
      image={statisticImage}
      text={childContentfulComponentStatBlocksStatisticTextNode.childMarkdownRemark.html}
      source={source}
    />
  )
}

export default ContentfulStatBlock
