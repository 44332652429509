import React from 'react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'

const StatImage = ({ image }) => {
  if (!image) return null
  return (
    <Img
      className="stat-image"
      image={image.gatsbyImageData}
      alt={image.description || image.title || 'Untitled Image'}
    />
  )
}

const StatText = ({ text }) => {
  if (!text) return null
  return <span className="stat-text" dangerouslySetInnerHTML={{ __html: text }} />
}

const StatSource = ({ source }) => {
  if (!source) return null
  return <p className="stat-source">{source}</p>
}

function StatBlock({ source, image, text }) {
  return (
    <div className="stat-block">
      <StatImage image={image} />
      <StatText text={text} />
      <StatSource source={source} />
    </div>
  )
}

export default StatBlock
