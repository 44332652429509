import React, { useContext } from 'react'
import { Reoverlay } from 'reoverlay'
import InquireModal from 'components/modals/inquireModal'
import Container from 'components/common/Container'
import { CampaignContext } from 'store'

// parse out the extra text node and return if it exists
const CTAText = ({ text }) => {
  if (!text) return null
  // eslint-disable-next-line react/no-danger
  return <div className="ctaText" dangerouslySetInnerHTML={{ __html: text }} />
}

function CTA({ text, cssClass, buttonText }) {
  const slug = useContext(CampaignContext)

  const signMeUp = () => {
    Reoverlay.showModal(InquireModal, {
      program: slug,
    })
  }

  return (
    <>
      <CTAText text={text} />
      <Container type="wrapper" className={`cta-container ${cssClass}`}>
        <button type="button" className="btn btn-primary" onClick={signMeUp}>
          <span>{buttonText}</span>
        </button>
      </Container>
    </>
  )
}

export default CTA
