import React from 'react'
import ParagraphWithHeadline from 'components/ParagraphWithHeadline'

function ContentfulParagraphWithHeadline({ data }) {
  const { childContentfulComponentParagraphWithHeadlineParagraphTextNode, headlineText, cssClass } = data
  let paragraphHtml
  if (childContentfulComponentParagraphWithHeadlineParagraphTextNode) {
    paragraphHtml = childContentfulComponentParagraphWithHeadlineParagraphTextNode.childMarkdownRemark.html
  }
  return <ParagraphWithHeadline headline={headlineText} paragraph={paragraphHtml} cssClass={cssClass} />
}

export default ContentfulParagraphWithHeadline
