/* eslint-disable react/destructuring-assignment */
/* eslint no-undef: 0 */
import React, { useEffect } from 'react'
import useScript from './useScript'

const JsonLd = ({ data }) => (
  <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }} />
)

const YouVisitIWC = ({ institution, containerHeight, containerWidth }, props) => {
  const data = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: props.title,
    description: props.description ? props.description : 'Interactive Image Element',
    thumbnailUrl: props.thumb ? props.thumb : '',
    uploadDate: props.uploadDate ? props.uploadDate : '2020-03-31T08:00:00+08:00',
    publisher: {
      '@type': 'Organization',
      name: 'EAB',
      logo: {
        '@type': 'ImageObject',
        url: 'https://attachment.eab.com/wp-content/uploads/2019/07/EAB-Logo-RGB.png',
        width: 500,
        height: 193,
      },
    },
    contentUrl: 'url of actual tour',
    embedUrl: 'url where the tour has been placed',
    interactionCount: 'locations.views',
  }

  const YVSource = 'https://www.youvisit.com/tour/Embed/js3'
  const status = useScript(YVSource)

  useEffect(() => {
    const yvObj = window.YVScript
    if (status === 'ready') {
      // eslint-disable-next-line no-unused-expressions
      yvObj && yvObj.scanEmbeds()
    }
  })

  // sets up the correct anchor props
  const anchorProps = {
    href: 'https://www.youvisit.com',
    title: props.title,
    'data-inst': institution,
    'data-link-type': props.linkType,
    'data-loc': props.location,
    'data-platform': 'v',
    'data-type': props.type,
    'data-stopid': props.stopId,
    'data-hover-width': props.hoverWidth,
    'data-hover-height': props.hoverHeight,
    'data-image-width': containerWidth,
    'data-image-height': containerHeight,
  }
  if (props.type === 'hover-panel') {
    delete anchorProps['data-type']
  }
  if (typeof props.stopId === 'undefined') {
    delete anchorProps['data-stopid']
  }
  if (typeof props.location === 'undefined' || props.location === null) {
    delete anchorProps['data-loc']
  }

  return (
    <div className="vt-container">
      {/* <a className="virtualtour_embed" {...anchorProps}>
          Virtual Tour
        </a> */}
      <a
        alt="Launch Experience"
        href={`https://www.youvisit.com/#/vte/?data-platform=v&data-link-type=immersive&data-inst=${institution}&data-image-width=100%&data-image-height=100%&`}
      >
        Launch Full Experience
      </a>
      <JsonLd data={data} />
    </div>
  )
}

export default YouVisitIWC

YouVisitIWC.defaultProps = {
  containerHeight: '100%',
  containerWidth: '100%',
  title: 'Launch Experience',
  linkType: 'immersive',
  type: 'inline-embed',
  hoverWidth: '90%',
  hoverHeight: '70%',
  loadStopOnly: '0',
  experience_type: 'iwc',
}
