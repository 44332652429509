import React from 'react'
import HeroImage from 'utils/content-modules/ContentfulHeroImage'
import Head from 'components/theme/Head'
import Container from 'components/common/Container'

function Header({ image, children }) {
  return (
    <>
      <Head />
      <Container className="hero-section">
        <HeroImage data={image} />
        <Container constraints="centered" className="hero-content-container">
          <div className="hero-content">{children}</div>
        </Container>
      </Container>
    </>
  )
}

export default Header
