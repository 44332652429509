import styled from '@emotion/styled'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  @media screen and (min-width: 800px) {
    flex: 1;
  }
`
