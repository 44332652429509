import React from 'react'
import Header from 'components/Header'
import ContentfulComponentCallToActionCta from './ContentfulComponentCallToActionCta'
import ContentfulComponentHeadline from './ContentfulComponentHeadline'

const ContentfulHeader = ({ data: { image, cta, headline } }) => (
  <Header image={image}>
    <ContentfulComponentHeadline data={headline} />
    <ContentfulComponentCallToActionCta data={cta} />
  </Header>
)

export default ContentfulHeader
