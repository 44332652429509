import React from 'react'

const Paragraph = ({ paragraphHtml }) => (
  <div
    className="text-container"
    dangerouslySetInnerHTML={{
      __html: paragraphHtml,
    }}
  />
)

export default Paragraph
