import React from 'react'

const Headline = ({ head, subHead, cssClass }) => (
  <div className={`card-container ${cssClass}`}>
    <div className="headline" dangerouslySetInnerHTML={{ __html: head }} />
    <div className="sub-headline" dangerouslySetInnerHTML={{ __html: subHead }} />
  </div>
)

export default Headline
