import React from 'react'
import Headline from 'components/Headline'

function ContentfulHeadline({ data }) {
  const {
    childContentfulComponentHeadlineHeadlineTextNode: { childMarkdownRemark: { html: headlineNodeHtml = '' } = {} },
    childContentfulComponentHeadlineSubHeadlineTextNode = {},
    cssClass,
  } = data
  let subHeadlineHtml
  if (childContentfulComponentHeadlineSubHeadlineTextNode) {
    subHeadlineHtml = childContentfulComponentHeadlineSubHeadlineTextNode.childMarkdownRemark.html
  }
  return <Headline head={headlineNodeHtml} subHead={subHeadlineHtml} cssClass={cssClass} />
}

export default ContentfulHeadline
